@import '../../styles/vars.scss';
@import '../../styles/fonts.scss';

$navResponsiveBreakpoint: 1380px;

.nav-module {

    .section-sticky {
        background-color: #FFFFFF;
        padding-top: 36px;
        padding-bottom: 36px;

        @include respond-to('max', $navResponsiveBreakpoint) {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    .brand-logo-container {
        display: inline-block;
        vertical-align: middle;
        width: 174px;

        @include respond-to('max', $navResponsiveBreakpoint) {
            width: 124px;
        }

        .brand-logo {
            display: inline-block;
            width: 120px;

            @include respond-to('max', $navResponsiveBreakpoint) {
                width: 104px;
            }
        }
    }

    .location-pick-button {
        display: inline-flex;
        justify-items: center;
        align-items: center;
        background: none;
        border: none;
        color: $black2;
        cursor: pointer;
        margin-right: 10px;

        .location-pick-icon {
            height: 24px;
            width: 24px;
            margin-left: 5px;

            @include respond-to('max', $navResponsiveBreakpoint) {
                height: 20px;
                width: 20px;
            }
        }
    }

    .nav-link-container {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        vertical-align: middle;
        width: calc(100% - 174px);

        .nav-link {
            display: inline-block;
            font-family: 'roboto';
            font-size: 1rem;
            line-height: 1.5rem;
            vertical-align: middle;
            color: $black3;
            margin-right: 3rem;
            transition: all .2s ease-in-out;

            &:hover, &.active {
                color: $green1;
            }
        }

        .float-left {
            display: inline-block;
            vertical-align: middle;
        }

        .float-right {
            vertical-align: middle;
            float: right;
        }

        .portal-login-cta {
            margin-right: 2rem;
        }

        .booking-cta {
            font-size: 0.9rem;
        }
    }


    .nav-toggle-container {
        vertical-align: middle;
        float: right;

        .toggle-icon {
            display: inline-block;
            height: 24px;
            width: 24px;
            float: right;
    
            line {
                stroke: $black2;
                stroke-width: 2px;
            }
        }
    }
    
    .nav-link-container-responsive {
        position: relative;
        background-color: $white1;
        height: calc(100vh - 60px);
        padding-top: 2rem;
        padding-bottom: 5rem;
        overflow: scroll;
        transform: translateY(0px);

        @keyframes nav-link-container-responsive-anim {
            from { opacity: 0; }
            to { opacity: 1; }
        }

        animation: nav-link-container-responsive-anim .3s ease-in-out;

        .nav-link-responsive {
            display: block;
            font-family: 'roboto-light';
            color: $black3;
            margin-top: 3rem;
            font-size: 2.5rem;
            line-height: 3.5rem;
            
            @include respond-to('max', '400px') {
                margin-top: 2.5rem;
                font-size: 2rem;
                line-height: 3rem;
            }

            @include respond-to('max', '350px') {
                margin-top: 2rem;
                font-size: 1.625rem;
                line-height: 2.375rem;
            }

            @include orientation('landscape') {
                order: 1;
            }
        }

        .action-button-responsive {
            // width: 85%;
            // position: absolute;
            // bottom: 9rem;
            // left: 0px; right: 0px;
            // margin: auto;
            width: 100%;
            margin-top: 2rem;
            text-align: center;

            a {
                width: 100%;
                font-size: 1.2rem;
            }

            @include orientation('landscape') {
                position: relative;
                width: auto;
                order: 0;
                bottom: auto;
                left: auto; right: auto;
                text-align: left;
                margin-top: 3rem;

                a {
                    width: auto;
                }
            }
        }
    }
}
