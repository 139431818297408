@import '../../styles/vars.scss';
@import '../../styles/fonts.scss';

.banner {
  background-color: #1B6835;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;

  @include respond-to('max', $medium-width) {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .banner-msg {
    margin: 0px;
    color: $white1;
    font-family: 'roboto';

    @include respond-to('max', $medium-width) {
      line-height: 1.5rem;
    }

    strong {
      font-weight: 800;
    }
  }
}