@import "../../styles/vars.scss";

.background-overlay {
  background-color: $black2;
  opacity: 0.5;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99999;
}

.location-picker {
  display: flex;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  max-width: 1550px;
  width: calc(100% - 200px);
  height: 566px;
  padding: 20px 0px;
  z-index: 100000;
  background-color: $white1;
  overflow: auto;

  @include respond-to("max", $xlarge-width) {
    justify-content: flex-start;
    width: 100vw;
    height: 100vh;
    padding: 40px 0px;
    padding-top: 120px;
  }

  @include respond-to("max", "500px") {
    padding-bottom: 100px;
  }

  .location-picker-header {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    padding: 20px 40px;

    @include respond-to("max", $large-width) {
      background-color: #ffffff;
    }

    .brand-logo {
      display: none;
      width: 120px;
      vertical-align: middle;

      @include respond-to("max", $large-width) {
        display: inline-block;
        width: 104px;
      }
    }

    .close-button {
      float: right;
      border: none;
      background: none;
      width: 32px;
      height: 32px;
      cursor: pointer;
      vertical-align: top;

      &:disabled {
        cursor: not-allowed;
      }

      img {
        width: 20px;
      }
    }
  }

  .heading {
    color: $black1;
    text-align: center;
  }

  .location-list-container {
    width: 100%;
    max-height: 500px;
    padding-left: 40px;
    padding-right: 40px;
    overflow: scroll;
    text-align: center;

    @include respond-to("max", "500px") {
      max-height: unset;
    }

    .location-list {
      list-style: none;
      width: 100%;
      padding: 0px;
      margin: 0px;

      li {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        width: calc(50% - 80px);
        background-color: $green1;
        margin: 20px 40px;
        padding: 40px 20px;
        cursor: pointer;

        &.disabled {
          cursor: not-allowed !important;
          background-color: $gray1 !important;
          pointer-events: none;
        }

        @include respond-to("max", $large-width) {
          width: calc(50% - 30px);
          padding: 60px 20px;
          margin-left: 10px;
          margin-right: 10px;
        }

        @include respond-to("max", $large-width) {
          width: 100%;
          padding: 60px 20px;
          margin-left: 0px;
          margin-right: 0px;
        }

        &:hover {
          background-color: darken($green1, 10%);
        }

        p {
          font-size: 1.6rem;
          color: $white1;
          text-align: center;

          &.location-name {
            margin-top: 0px;
            margin-bottom: 45px;
            font-size: 2rem;

            @include respond-to("max", $medium-width) {
              margin-bottom: 25px;
            }
          }

          &.location-address {
            margin-top: 0px;
            margin-bottom: 0px;
            font-size: 1.5rem;
            line-height: 2.6rem;
          }
        }
      }
    }
  }

  .auto-detect-location-cta {
    vertical-align: middle;
    margin: 10px 0px 40px 0px;
    cursor: pointer;

    &::after {
      content: none;
    }

    &:hover {
      color: darken($green1, 5%);
    }

    @include respond-to("max", $large-width) {
      background-color: #ffffff;
      position: fixed;
      bottom: 0px;
      width: 100%;
      padding: 30px 20px;
      margin: 0px;
      text-align: center;
      box-shadow: 0px -10px 25px rgba(0, 0, 0, 0.05);
    }
  }

  @keyframes loading-icon-anim {
    from {
      transform: rotateZ(0deg);
    }
    to {
      transform: rotateZ(360deg);
    }
  }

  .loading-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    animation: loading-icon-anim 2s linear 0s infinite;
    vertical-align: middle;
    margin-left: 10px;
    margin-bottom: 2px;
  }
}
