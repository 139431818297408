.ReactModalPortal {
    position: absolute;
    z-index: 10000;
    
    .ReactModal__Content {
        top: 0px !important;
        bottom: 0px !important;
        left: 0px !important;
        right: 0px !important;
        border-radius: 0px !important;
        inset: 0px 0px 0px 0px !important;
        background: #000 !important;
        border: none !important;

        .close-button {
            float: right;
            font-family: 'roboto';
            border: none;
            font-size: 20px;
            text-transform: uppercase;
            padding: 20px;
            border-radius: 50px;
            height: 50px;
            width: 50px;
            line-height: 50px;
            padding: 0;
            cursor: pointer;
            transition: 0.3s all ease-in-out;
            background-color: $white1;
            color: $black1;

            &:hover {
                background: $black1;
                color: $white1;
            }
        }

        video {
            position: absolute;
            max-width: 70vw;
            margin: auto;
            top: 0px; bottom: 0px;
            left: 0px; right: 0px;
            
            @include orientation('portrait') {
                max-width: 100%;
                max-height: none;
            }
        }
    }
}