/*
 * ====================================================================
 * globals.scss
 * 	Used to set some global styles for your project that are used on
 * 	ALL pages.
 * ====================================================================
 */

@mixin scrollbars($size, $foreground-color, $background-color: transparent) {
  // For Google Chrome
  ::-webkit-scrollbar {
      width:  $size;
      height: $size;
  }

  ::-webkit-scrollbar-thumb {
      background: $foreground-color;
  }

  ::-webkit-scrollbar-track {
      background: $background-color;
  }

  // For Internet Explorer
  body {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}

::-moz-selection {
  color: #fff;
  background: #000;
}

::selection {
  color: #fff;
  background: #000;
}

// * {
//   .desktop & {
//     @include scrollbars($scrollbar-size, #FFF);
//   }
// }

 body, html {
  width: 100%;
  min-height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-weight: 300;
  font-size: 16px;
  background-color: $white1;

  font-family: 'roboto';

  @include respond-to("max", $large-width) {
    font-size: 15px;
  }
  @include respond-to("max", $medium-width) {
    font-size: 14px;
  }
  @include respond-to("max", $small-width) {
    font-size: 14px;
  }
}

hr {
  border-top: 1px solid #C3C3C3;
  border-bottom: none;
}

.absolute-center {
  position: absolute;
  display: block;
  top: 0; right: 0; bottom:0; left: 0;
  margin: auto;
}

.container {
  @include respond-to('max', $small-width) {
    width: calc(100vw - 30px);
  }
}

p {
  font-family: 'roboto-light';
  font-size: 1rem;
  line-height: 1.625rem;
  margin-bottom: 1.5rem;
  color: $black1;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

h1 {
  font-family: 'roboto';
  font-size: 5rem;
  line-height: 4rem;
  color: #FFFFFF;

  @include respond-to('max', $small-width) {
    font-size: 3.375rem;
    line-height: 4.25rem;
  }
}

h2 {
  font-family: 'roboto-light';
  font-size: 3.375rem;
  line-height: 4.25rem;
  color: #FFFFFF;

  @include respond-to('max', $small-width) {
    font-size: 2.25rem;
    line-height: 3.125rem;
  }
}

h3 {
  font-family: 'roboto-light';
  font-size: 2.25rem;
  line-height: 3.25rem;
  color: #FFFFFF;
  
  @include respond-to('max', $small-width) {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }
}

h4 {
  font-family: 'roboto-light';
  font-size: 1.625rem;
  line-height: 2.375rem;
  color: #FFFFFF;

  @include respond-to('max', $small-width) {
    font-size: 1.375rem;
    line-height: 2rem;
  }
}

h5 {
  font-family: 'roboto-light';
  font-size: 1.25rem;
  line-height: 2rem;
  color: #FFFFFF;
}

h6 {
  font-family: 'roboto-light';
  font-size: 0.875rem;
  line-height: 1rem;
  color: #FFFFFF;
}

strong {
  font-family: 'roboto';
}

@supports (-webkit-text-stroke: 1px black) {
  .outline {
    color: $secondary;
    -webkit-text-stroke: 1px white;
    -webkit-text-fill-color: $secondary;
  }
}

.disable-selection {
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}

.flex-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.button {
  position: relative;
  font-family: 'roboto-medium';
  display: inline-block;
  background-color: $orange1;
  color: #FFFFFF;
  font-size: 1rem;
  width: fit-content;
  cursor: pointer;
  border: none;
  border-radius: 0px;
  padding: 15px 40px;
  text-decoration: none;
  transition: all .7s ease-in-out;

  &::before {
    content: ' ';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 0px;
    height: 100%;
    background-color: darken($orange1, 5%);
  }

  @keyframes button-anim {
    0% { width: 0%; left: 0px; right: auto; }
    50% { width: 100%; left: 0px; right: auto; }
    51% { left: auto; right: 0px; }
    100% { width: 0% }
  }

  &:hover {
    // color: $white1;
    text-decoration: none;
    background-color: darken($orange1, 5%);

    &::before {
      animation: button-anim .5s ease-in-out;
      width: 0%;
      left: auto; right: 0px;
    }
  }

  &.alt {
    background-color: $black1;

    &::before {
      background-color: $orange1;
    }

    &:hover {
      color: $white1;
      background-color: $orange1;

      &::before {
        animation: button-anim .5s ease-in-out;
        width: 0%;
        left: auto; right: 0px;
      }
    } 
  }
}

.button-arrow {
  position: relative;
  font-family: 'roboto';
  color: $black1;
  font-size: 1.125rem;
  line-height: 2.375rem;
  align-self: flex-start;
  width: auto;

  &::after {
    content: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjAgMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yMC4wMDA3IDYuNzQwODZMMTkuMTEwMiA3LjY4NDI4TDEzLjkxNzQgMTMuMTg1M0wxMy4wMjY5IDEyLjI0MTlMMTcuNTY0OSA3LjQzNDQ0SDAuODMzOTg0VjYuMTAwMjRIMTcuNjE0OUwxMy4wMjY5IDEuMjM5ODFMMTMuOTE3NCAwLjI5NjM4N0wxOS4xMTAyIDUuNzk3NEwyMC4wMDA3IDYuNzQwODZaIiBmaWxsPSIjMzQzNDM0Ii8+PC9zdmc+Cg==");
    position: absolute;
    right: -30px;
    top: 0px;
    width: 15px;
    height: 10px;
    transition: all .25s ease-in-out;
  }
  
  &.light {
    color: $white1;
    &::after {
      content: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjAgMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yMC4wMDA3IDYuNzQwODZMMTkuMTEwMiA3LjY4NDI4TDEzLjkxNzQgMTMuMTg1M0wxMy4wMjY5IDEyLjI0MTlMMTcuNTY0OSA3LjQzNDQ0SDAuODMzOTg0VjYuMTAwMjRIMTcuNjE0OUwxMy4wMjY5IDEuMjM5ODFMMTMuOTE3NCAwLjI5NjM4N0wxOS4xMTAyIDUuNzk3NEwyMC4wMDA3IDYuNzQwODZaIiBmaWxsPSIjRkFGQUZBIi8+PC9zdmc+");
    }
    &:hover {
      color: $white1;
    }
  }

  &:hover {
    text-decoration: none;
    color: $black1;

    &::after {
      right: -40px;
    }
  }
}

.section-content {
  padding-left: 7.5%;
  padding-right: 7.5%;
  margin: auto;
}

a {
  text-decoration: none;

  &.text-link {
    position: relative;
    color: $green1;
    text-decoration: none;
    font-family: 'roboto';
    
    &::after {
      content: ' ';
      background-color: $green1;
      position: absolute;
      bottom: 0px;
      left: 0px;
      height: 1px;
      width: 0px;
      
      .desktop & {
        transition: 0.25s all ease-in-out;
        &::after {
          transition: 0.25s all ease-in-out;
        }
      }
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }

  }
}

.text-body-small {
  font-family: 'roboto-light';
  font-size: 1rem;
  line-height: 1.625rem;
}

.text-body-medium {
  font-family: 'roboto-light';
  font-size: 1.125rem;
  line-height: 1.875rem;
}

.text-body-large {
  font-family: 'roboto-light';
  font-size: 1.625rem;
  line-height: 2.625rem;

  @include respond-to('max', $small-width) {
    font-size: 1.25rem;
    line-height: 2rem;
  }
}

.bold {
  font-family: 'roboto' !important;
}